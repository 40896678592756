import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { participant_png, state_topper_png, class_topper_png, scholarship_png } from '../../../../../assets';
import { participant_webp, state_topper_webp, class_topper_webp, scholarship_webp } from '../../../../../assets';
import { tile_desktop_png, tile_desktop_webp } from '../../../../../assets';
import './desktopAwards.css'
import '../awards.css'

const DesktopAwards = () => {
    const titleStyleDesktop = {
        fontSize: '4vw',
        margin: '0',
        marginTop: '0vw',
        marginBottom: '5vw',
        padding: '0'
    }

    function supportsWebP() {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                const result = (img.width > 0) && (img.height > 0);
                resolve(result);
            };
            img.onerror = () => resolve(false);
            img.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
        });
    }

    const [backgroundDesktop, setBackgroundDesktop] = useState('');

    useEffect(() => {
        supportsWebP().then(supported => {
            if (supported) {
                setBackgroundDesktop(tile_desktop_webp);
            } else {
                setBackgroundDesktop(tile_desktop_png);
            }
        });
    }, []);

    const desktopStyle = {
        backgroundImage: `url(${backgroundDesktop})`,
    };

    return (
        <div className="awards" id='AwardsDesktop' style={desktopStyle}>
            <div className="awards-content desktop-awards-content">
                <h2 className="awards-title desktop-awards-title" style={titleStyleDesktop}>
                    <div className='awards-title-content'>
                        Awards
                    </div>
                </h2>
                <div className='awards-container'>
                    <div className='awards-category non-state-topper-padding'>
                        <div className='awards-category-title' id='ParticipantAwards'>
                            <div className='awards-category-title-text'>All Participants</div>
                            <div className='awards-category-title-image non-state-topper-image'>
                                <picture>
                                    <source type="image/webp" srcSet={participant_webp} />
                                    <img src={participant_png} className='awards-title-image' alt="Participant Image"></img>
                                </picture>
                            </div>
                        </div>
                        <div className='awards-category-text'>
                            <div className='awards-category-text-main participation' style={{ marginBottom: '1vw' }}>Certificate of Participation</div>
                            <div className='awards-category-text-normal'>All participants will receive a Certificate of Participation, except those who are toppers.</div>
                        </div>
                    </div>
                    <div className='awards-category'>
                        <div className='awards-category-title state-topper-category-title' id='StateAwards'>
                            <div className='awards-category-title-text'>State Toppers</div>
                            <div className='awards-category-title-image state-topper-image'>
                                <picture>
                                    <source type="image/webp" srcSet={state_topper_webp} />
                                    <img src={state_topper_png} className='awards-title-image' alt="State Topper Image"></img>
                                </picture>
                            </div>
                        </div>
                        <div className='awards-category-text' style={{ paddingBottom: '0' }}>
                            <div className='awards-scholarship-image'>
                                <picture>
                                    <source type="image/webp" srcSet={scholarship_webp} />
                                    <img src={scholarship_png} className='awards-scholarship-image' alt="Scholarship Text Background Image"></img>
                                </picture>
                                <div className='overlay-content'>
                                    <h3 className='awards-scholarship-text tinos-font'>Scholarship</h3>
                                </div>
                            </div>
                        </div>
                        <div className='awards-category-text-normal' style={{ fontSize: '2vw', padding: '0.25vw 0' }}>&</div>
                        <div className='awards-category-text-main state'>Certificate of Excellence</div>
                    </div>
                    <div className='awards-category non-state-topper-padding'>
                        <div className='awards-category-title' id='ClassAwards'>
                            <div className='awards-category-title-text'>Class Toppers</div>
                            <div className='awards-category-title-image non-state-topper-image'>
                                <picture>
                                    <source type="image/webp" srcSet={class_topper_webp} />
                                    <img src={class_topper_png} className='awards-title-image' alt="Class Topper Image"></img>
                                </picture>
                            </div>
                        </div>
                        <div className='awards-category-text'>
                            <div className='awards-category-text-main class gold'>Medal of Merit</div>
                            <div className='awards-category-text-normal' style={{ fontSize: '2vw', padding: '0.2vw 0' }}>&</div>
                            <div className='awards-category-text-main class'>Certificate of Merit</div>
                        </div>
                    </div>
                </div>
                <div className='awards-guidelines-text'>
                    Please review the <Link to={'/guidelines'} style={{ fontWeight: '800', padding: '0vw 0.5vw' }}>Guidelines</Link> to learn more about how the toppers are chosen.
                </div>
            </div>
        </div>
    )
}

export default DesktopAwards