import { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { bounceContainerById } from '../animations/BounceComponent';
// import { shakeContainerById } from '../animations/ShakeComponent';

export function DelayedLink({ delay, isLocked, titleDivId, to, divId, isBounce, replace, state , ...props }) {
  const navigate = useNavigate();
  const timerRef = useRef();

  useEffect(() => () => clearTimeout(timerRef.current), []);

  const clickHandler = async (e) => {
    e.preventDefault();
    if(isBounce) bounceContainerById(divId)
    if (!isLocked) {
      timerRef.current = setTimeout(navigate, delay, to, { replace, state });
    } else {
      // shakeContainerById(titleDivId)
      // timerRef.current = setTimeout(delay);
    }
  };

  return (
    <Link to={to} {...props} state={state} onClick={clickHandler}/>

  )
}