import React from 'react';
import './guidelinesPage.css'
import { Guidelines } from './containers';

const GuidelinesPage = () => {
    return (
        <div className='GuidelinesPage'>
            <Guidelines/>
        </div>
    )
}

export default GuidelinesPage