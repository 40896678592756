import { img1_png, img2_png, img3_png, img4_png, img5_png, img6_png, img7_png, img8_png, img9_png, img10_png, img11_png, img12_png, very_low_res_img_png, very_low_res_img_webp } from '../assets';
import { img1_low_png, img2_low_png, img3_low_png, img4_low_png, img5_low_png, img6_low_png, img7_low_png, img8_low_png, img9_low_png, img10_low_png, img11_low_png, img12_low_png } from '../assets';

import { img1_webp, img2_webp, img3_webp, img4_webp, img5_webp, img6_webp, img7_webp, img8_webp, img9_webp, img10_webp, img11_webp, img12_webp } from '../assets';
import { img1_low_webp, img2_low_webp, img3_low_webp, img4_low_webp, img5_low_webp, img6_low_webp, img7_low_webp, img8_low_webp, img9_low_webp, img10_low_webp, img11_low_webp, img12_low_webp } from '../assets';

export const formatCategoryName = (name) => {
    const words = name.toUpperCase().split('-');

    // If there's a second word, wrap it with a span element with the desired class.
    if (words.length > 1) {
        words[1] = `<span class="theme-category-title-class-number">${words[1]}</span>`;
    }

    return words.map(word => `<div>${word}</div>`).join('');
};

export const patterns = {
    'Grammar': [{
        pattern: ['10 Multiple Choice Questions','15 Multiple Choice Questions'],
        time: 15,
    }],
    'Reading': [{
        pattern: '5 Multiple Choice Questions',
        time: 15,
    }],
    'Writing': [{
        pattern: '1 Question',
        wordLimit: ['20 - 30 words','30 - 40 words','40 - 60 words','70 - 90 words','100 - 120 words','140 - 150 words','160 - 180 words','180 - 200 words','180 - 200 words','180 - 200 words','200 - 250 words','200 - 250 words'],
        time: 15,
    }],
    'Puzzles': [{
        pattern: '10 Clues',
        time: 15,
    }]
}

export const classes = [
    {
        id: 1,
        name: 'Class-1',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img1_low_png, img1_png],
        imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        'Grammar': [
            {
                topic: 'Words and their Meanings: Introduction',
            },
            {
                topic: 'Nouns: Introduction to Basic Nouns',
            },
            {
                topic: 'Verbs: Introduction to Basic Verbs',
            },
            {
                topic: 'Genders: Masculine and Feminine',
            },
            {
                topic: 'Articles: Introduction (a, an, the)',
            },
            {
                topic: 'Prepositions: Introduction to Place (in, on, under)',
            },
            {
                topic: 'Antonyms: Introduction to Words and their Opposites',
            },
            {
                topic: 'Sentence Basics: Structure of Statements',
            },
        ],
        'Reading': [
            {
                textGenre: 'Short Story or Nursery Rhyme'
            },
        ],
        'Writing': [
            {
                option1: 'Describe your dream house.',
                option2: 'Write about your favorite character from a cartoon or book.',
                option3: 'Write about a day spent with your best friend. What did you do, and why was it special?',
            },
        ],
        'Puzzles': [
            {
                topic: '\"Around the Home\"',
                theme: 'Focus on common objects found around the home.',
                examples: 'Bed, Lamp, Dog, Cat, Ball, Door, Wall, Chair'
            },
        ],
    },
    {
        id: 2,
        name: 'Class-2',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img2_low_png, img2_png],
        imageWebp: [very_low_res_img_webp, img2_low_webp, img2_webp],
        'Grammar': [
            {
                topic: 'Nouns: Singular and Plural (and their use with Articles)',
            },
            {
                topic: 'Verbs: Present Simple and Past Simple Tenses',
            },
            {
                topic: 'Pronouns, Adjectives and Adverbs: Introduction',
            },
            {
                topic: 'Prepositions and Conjunctions: Introduction',
            },
            {
                topic: 'Synonyms and Antonyms: Building Vocabulary',
            },
            {
                topic: 'Sentence Punctuation: Full Stop, Comma, and Question Mark',
            },
            {
                topic: 'Sentence Components: Subject and Predicate',
            },
            {
                topic: 'Sentence Structure: Making Statements and Asking Questions',
            },
        ],
        'Reading': [
            {
                textGenre: 'Short Story, Moral Story, or School Event'
            },
        ],
        'Writing': [
            {
                option1: 'Write a short story about an adventure in space.',
                option2: 'Describe the best day you spent with your family.',
                option3: 'Imagine you have a pet dinosaur. Describe a day in your life.',
            },
        ],
        'Puzzles': [
            {
                topic: '\"In the Classroom\"',
                theme: 'Objects or concepts that are familiar in a classroom setting.',
                examples: 'Desk, Read, Book, Pen, Pencil, Paper, Erase, Clock'
            },
        ],
    },
    {
        id: 3,
        name: 'Class-3',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img3_low_png, img3_png],
        imageWebp: [very_low_res_img_webp, img3_low_webp, img3_webp],
        'Grammar': [
            {
                topic: 'Nouns: Common and Proper Nouns (and their use with Articles)',
            },
            {
                topic: 'Pronouns: Personal (I, you, he, she) and Possessive (my, your, his, her)',
            },
            {
                topic: 'Verbs: Present, Past, and Future Tenses in Simple form',
            },
            {
                topic: 'Adjectives: Descriptive and Comparative',
            },
            {
                topic: 'Adverbs: Manner and Time',
            },
            {
                topic: 'Basic Prepositions and Conjunctions',
            },
            {
                topic: 'Introduction to Determiners',
            },
            {
                topic: 'Sentence Structure: Statements, Questions, Commands, and Exclamations',
            },
        ],
        'Reading': [
            {
                textGenre: 'Poem, Short Adventure Tale, or Letter'
            },
        ],
        'Writing': [
            {
                option1: 'Write a letter to a storybook character with questions about their adventure.',
                option2: 'Describe what you think the world will look like 100 years from now.',
                option3: 'Write an invitation to a make-believe event (birthday on the moon, tea under the sea, etc.). Be creative in your details!',
            },
        ],
        'Puzzles': [
            {
                topic: '\"At the Park\"',
                theme: 'Things seen, or activities done at a park.',
                examples: 'Swing, Slide, Tree, Grass, Play, Run, Bird, Flower'
            },
        ],
    },
    {
        id: 4,
        name: 'Class-4',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img4_low_png, img4_png],
        imageWebp: [very_low_res_img_webp, img4_low_webp, img4_webp],
        'Grammar': [
            {
                topic: 'Nouns: Introduction to Collective Nouns (and their use with Articles)',
            },
            {
                topic: 'Pronouns: Reflexive Pronouns (myself, herself, etc.)',
            },
            {
                topic: 'Verbs: Action and Linking Verbs; All tenses in both Simple and Continuous forms',
            },
            {
                topic: 'Adjectives: Comparative and Superlative forms',
            },
            {
                topic: 'Adverbs: Manner, Time, and Frequency',
            },
            {
                topic: 'Prepositions and Conjunctions',
            },
            {
                topic: 'Interjections: Expressing Emotion (e.g., Oh!, Wow!, Ouch!)',
            },
            {
                topic: 'Sentence Punctuation and Advanced Sentence Structure',
            },
        ],
        'Reading': [
            {
                textGenre: 'Folktale or Diary Entry'
            },
        ],
        'Writing': [
            {
                option1: 'Write a diary entry about a mysterious discovery in your backyard.',
                option2: 'Create a new holiday and explain how and why it is celebrated.',
                option3: 'Write a diary entry from the perspective of a household pet or a wild animal.',
            },
        ],
        'Puzzles': [
            {
                topic: '\"Cities and Buildings\"',
                theme: 'Introduce more complex ideas relating to infrastructure.',
                examples: 'City, Bank, Park, Store, Street, Bridge, Tower, School'
            },
        ],
    },
    {
        id: 5,
        name: 'Class-5',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img5_low_png, img5_png],
        imageWebp: [very_low_res_img_webp, img5_low_webp, img5_webp],
        'Grammar': [
            {
                topic: 'Nouns: Advanced Singular and Plural forms; Abstract Nouns',
            },
            {
                topic: 'Pronouns: Demonstrative and Indefinite',
            },
            {
                topic: 'Verb Tenses including Modal Verbs (can, could, will, would)',
            },
            {
                topic: 'Adjectives in Context',
            },
            {
                topic: 'Adverbs: Comparison and Degrees',
            },
            {
                topic: 'Advanced Articles and Determiners',
            },
            {
                topic: 'Advanced Prepositions and Conjunctions',
            },
            {
                topic: 'Sentence Types: Simple, Compound',
            },
        ],
        'Reading': [
            {
                textGenre: 'Adventure Story or Biography'
            },
        ],
        'Writing': [
            {
                option1: 'Write an adventure story about an expedition to find a hidden city.',
                option2: 'Describe an invention you\'d like to create and its benefits.',
                option3: 'Create a superhero: describe their powers, responsibilities, and a villain they confront.',
            },
        ],
        'Puzzles': [
            {
                topic: '\"Space Adventure\"',
                theme: 'Words related to space and astronomy.',
                examples: 'Star, Moon, Comet, Space, Earth, Mars, Orbit, Galaxy'
            },
        ],
    },
    {
        id: 6,
        name: 'Class-6',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img6_low_png, img6_png],
        imageWebp: [very_low_res_img_webp, img6_low_webp, img6_webp],
        'Grammar': [
            {
                topic: '1. Parts of Speech Basics',
            },
            {
                topic: '2. Word Classes',
                description: [
                    '- Pronouns: Intensive (himself, herself) and Interrogative (who, what, which)',
                    '- Determiners: Demonstrative and Quantitative',
                    '- Adverbs: Place and Reason',
                    '- Conjunctions: Contrasting (although, however, nevertheless)'
                ],
            },
            {
                topic: '3. Verbs',
                description: [
                    '- Perfect Tenses: Present, Past, and Future',
                    '- Intro to Auxiliary Verbs (be, have, do)',
                ],
            },
            {
                topic: '4. Sentence Structure & Construction',
                description: [
                    '- Intro to Subject-Verb Agreement',
                    '- Intro to Phrases and Clauses',
                    '- Sentence Types: Simple, Compound'
                ],
            },
            {
                topic: '5. Voice & Representation',
                description: [
                    '- Intro to Active and Passive Voice',
                    '- Intro to Direct and Indirect Speech',
                ],
            },
        ],
        'Reading': [
            {
                textGenre: 'Story, News Report, or Interview'
            },
        ],
        'Writing': [
            {
                option1: 'Write a detailed guide about mastering a new skill of your choice.',
                option2: 'Create a short story incorporating an unexpected twist.',
                option3: 'Write a script for a short play about friendship or teamwork.',
            },
        ],
        'Puzzles': [
            {
                topic: '\"Water\"',
                theme: 'Explore life underwater and geographical formations.',
                examples: 'Fish, Coral, Shark, Whale, Ocean, Wave, Sand, Reef'
            },
        ],
    },
    {
        id: 7,
        name: 'Class-7',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img7_low_png, img7_png],
        imageWebp: [very_low_res_img_webp, img7_low_webp, img7_webp],
        'Grammar': [
            {
                topic: '1. Parts of Speech Basics',
            },
            {
                topic: '2. Word Classes',
                description: [
                    '- Pronouns: Relative (who, whose, whom, which, that)',
                    '- Determiners: Numerical and Distributive',
                    '- Interjections'
                ],
            },
            {
                topic: '3. Verbs',
                description: [
                    '- Present Perfect Continuous, Past Perfect Continuous',
                    '- Linking Verbs: Advanced',
                    '- Intro to Irregular Verbs',
                    '- Intro to Modal Verbs (can, could, may, might)',

                ],
            },
            {
                topic: '4. Sentence Structure & Construction',
                description: [
                    '- Clauses: Noun, Adverbial, and Relative',
                    '- Phrases: Noun, Verb, Adverbial, Prepositional',
                    '- Sentence Types: Complex',
                ],
            },
            {
                topic: '5. Voice & Representation',
                description: [
                    '- Active and Passive Voice',
                    '- Direct and Indirect Speech',
                ],
            },
        ],
        'Reading': [
            {
                textGenre: 'Story, Debate, or Book Review'
            },
        ],
        'Writing': [
            {
                option1: 'Write a review of your favorite book without giving away the ending.',
                option2: 'Write a persuasive letter to your principal suggesting a new club or activity for the school.',
                option3: 'Compose a series of journal entries documenting an exciting and imaginative expedition.',
            },
        ],
        'Puzzles': [
            {
                topic: '\"Travel and Transport\"',
                theme: 'Words associated with travel, transportation, and related concepts.',
                examples: 'Bicycle, Journey, Airplane, Train, Vehicle, Scooter, Traffic, Passport'
            },
        ],
    },
    {
        id: 8,
        name: 'Class-8',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img8_low_png, img8_png],
        imageWebp: [very_low_res_img_webp, img8_low_webp, img8_webp],
        'Grammar': [
            {
                topic: '1. Parts of Speech Basics',
            },
            {
                topic: '2. Word Classes',
                description: [
                    '- Adjectives: Determiners as Adjectives',
                    '- Pronouns: Reciprocal (each other, one another)',
                    '- Advanced Adverbs',
                    '- Prepositions and Conjunctions',
                ],
            },
            {
                topic: '3. Verbs',
                description: [
                    '- Tenses',
                    '- Modal Verbs (should, must, ought to)',
                    '- Transitive and Intransitive Verbs',
                ],
            },
            {
                topic: '4. Sentence Structure & Construction',
                description: [
                    '- Subject-Verb Agreement',
                    '- Conditionals: Zero, First, and Second',
                ],
            },
            {
                topic: '5. Voice & Representation',
                description: [
                    '- Active and Passive Voice',
                    '- Direct and Indirect Speech',
                    '- Transformation of Sentences',
                ],
            },
        ],
        'Reading': [
            {
                textGenre: 'Story, Personal Journal, or Narration'
            },
        ],
        'Writing': [
            {
                option1: 'Craft a suspenseful narrative about a legend in an abandoned castle.',
                option2: 'Describe a fictional place you would want to visit and why.',
                option3: 'Write a science fiction story that involves a futuristic gadget.',
            },
        ],
        'Puzzles': [
            {
                topic: '\"Exploring Nature\"',
                theme: 'Focus on elements found in nature, which are common and non-controversial.',
                examples: 'Valley, River, Mountain, Forest, Desert, Island, Prairie, Canyon'
            },
        ],
    },
    {
        id: 9,
        name: 'Class-9',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img9_low_png, img9_png],
        imageWebp: [very_low_res_img_webp, img9_low_webp, img9_webp],
        'Grammar': [
            {
                topic: '1. Parts of Speech Basics',
            },
            {
                topic: '2. Word Classes',
                description: [
                    '- Determiners: Articles vs Determiners',
                    '- Adverbs: Adverbs modifying Adjectives and other Adverbs',
                ],
            },
            {
                topic: '3. Verbs',
                description: [
                    '- Modal Auxiliaries: need, dare',
                    '- Intro to Non-finite Verbs',
                    '- Transitive and Intransitive Verbs',
                ],
            },
            {
                topic: '4. Sentence Structure & Construction',
                description: [
                    '- Phrases and Clauses',
                    '- Conditionals: Third and Mixed',
                    '- Comparisons: Degrees of Comparison',
                ],
            },
            {
                topic: '5. Voice & Representation',
                description: [
                    '- Active and Passive Voice',
                    '- Direct and Indirect Speech',
                    '- Transformation of Sentences',
                ],
            },
        ],
        'Reading': [
            {
                textGenre: 'Story, Analysis, or Travelogue'
            },
        ],
        'Writing': [
            {
                option1: 'Write an analytical commentary on the impact of technology on education.',
                option2: 'Create a fictional world and provide a detailed description of its inhabitants and culture.',
                option3: 'Develop a detective\'s diary, outlining the solving of a curious case.',
            },
        ],
        'Puzzles': [
            {
                topic: '\"Technology and Gadgets\"',
                theme: 'Common technology-related terms that are part of everyday life.',
                examples: 'Laptop, Mobile, Battery, Charger, Router, Screen, Keyboard, Speaker'
            },
        ],
    },
    {
        id: 10,
        name: 'Class-10',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img10_low_png, img10_png],
        imageWebp: [very_low_res_img_webp, img10_low_webp, img10_webp],
        'Grammar': [
            {
                topic: '1. Parts of Speech Basics',
            },
            {
                topic: '2. Word Classes',
                description: [
                    '- Pronouns',
                    '- Determiners',
                    '- Adverbs',
                    '- Prepositions & Conjunctions',
                ],
            },
            {
                topic: '3. Verbs',
                description: [
                    '- Tenses',
                    '- Basics of Non-finite Forms',
                    '- Linking, Intransitive, and Transitive Verbs',
                    '- Auxiliary Verbs',
                    '- Modals',
                ],
            },
            {
                topic: '4. Sentence Structure & Construction',
                description: [
                    '- Sentence Types: Simple, Compound, and Complex',
                    '- Subject-Verb Agreement',
                    '- Phrases and Clauses',
                    '- Comparisons',
                    '- Conditionals',
                ],
            },
            {
                topic: '5. Voice & Representation',
                description: [
                    '- Active and Passive Voice',
                    '- Direct and Indirect Speech',
                    '- Transformation of Sentences',
                ],
            },
        ],
        'Reading': [
            {
                textGenre: 'Story, News Article, or Narration'
            },
        ],
        'Writing': [
            {
                option1: 'Discuss the influence of art in everyday life.',
                option2: 'Create a story that is based on a strange dream.',
                option3: 'Write a first-person account of a historical event, as if you were there.',
            },
        ],
        'Puzzles': [
            {
                topic: '\"Historical Times\"',
                theme: 'Words associated with history and ancient civilizations.',
                examples: 'King, Queen, Castle, Crown, Sword, Battle, Throne, Empire'
            },
        ],
    },
    {
        id: 11,
        name: 'Class-11',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img11_low_png, img11_png],
        imageWebp: [very_low_res_img_webp, img11_low_webp, img11_webp],
        'Grammar': [
            {
                topic: '1. Parts of Speech Basics',
            },
            {
                topic: '2. Word Classes',
                description: [
                    '- Pronouns: Reflexive vs. Intensive, Ambiguous pronoun references',
                    '- Determiners: Emphatic (own, very)',
                    '- Adverbs',
                    '- Prepositions: Idiomatic use',
                ],
            },
            {
                topic: '3. Verbs',
                description: [
                    '- Verb Patterns: Verb + Gerund, Verb + Infinitive',
                    '- Advanced Modals: Expressing degrees of certainty, probability, necessity, and obligation',
                    '- Emphasizing with Auxiliary Verbs',
                ],
            },
            {
                topic: '4. Sentence Structure & Construction',
                description: [
                    '- Sentence Types: Compound-complex',
                    '- Conditionals: Inverted conditionals, wish/if only',
                    '- Ellipsis and Substitution',
                    '- Noun Clauses: Introductory Words, Embedded Questions, Reporting',                   
                ],
            },
            {
                topic: '5. Voice & Representation',
                description: [
                    '- Active and Passive Voice',
                    '- Direct and Indirect Speech',
                    '- Transformation of Sentences',
                ],
            },
        ],
        'Reading': [
            {
                textGenre: 'Thriller Story, Complex Narrative, Transcript of a Significant Indian Historical Speech, or Poetry'
            },
        ],
        'Writing': [
            {
                option1: 'Write a narrative that connects two unexpected genres (like sci-fi and historical fiction).',
                option2: 'Analyze the significance of symbolism in a famous work of art or literature.',
                option3: 'Construct a series of letters exchanged between two characters from different eras.',
            },
        ],
        'Puzzles': [
            {
                topic: '\"World of Sports\"',
                theme: 'Words associated with various sports and physical activities.',
                examples: 'Soccer, Tennis, Cricket, Racing, Dribble, Bowling, Skating, Marathon'
            },
        ],
    },
    {
        id: 12,
        name: 'Class-12',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img12_low_png, img12_png],
        imageWebp: [very_low_res_img_webp, img12_low_webp, img12_webp],
        'Grammar': [
            {
                topic: '1. Parts of Speech Basics',
            },
            {
                topic: '2. Word Classes',
                description: [
                    '- Advanced Determiners',
                    '- Advanced Adjectives',                   
                ],
            },
            {
                topic: '3. Verbs',
                description: [
                    '- Phrasal Verbs',
                    '- Advanced Verb Conjugation',
                    '- Causative Verbs: Have/Get something done',                   
                ],
            },
            {
                topic: '4. Sentence Structure & Construction',
                description: [
                    '- Adverbial Clauses: Time, Reason, Contrast',
                    '- Relative Clauses: Restrictive vs. Non-restrictive',
                    '- Modifiers: Misplaced and Dangling Modifiers',
                    '- Cleft Sentences',                   
                ],
            },
            {
                topic: '5. Voice & Representation',
                description: [
                    '- Active and Passive Voice',
                    '- Direct and Indirect Speech',
                    '- Transformation of Sentences',
                ],
            },
        ],
        'Reading': [
            {
                textGenre: 'Mystery Story, Complex Narrative, Transcript of a Significant Indian Historical Speech, or Poetry'
            },
        ],
        'Writing': [
            {
                option1: 'Compose a detailed critique of a film you believe is underrated.',
                option2: 'Write a comparative analysis of traditional schooling versus online learning experiences.',
                option3: 'Create a portfolio for an artist, real or imagined, including biography, artistic influences, and critiques of their most famous works.',
            },
        ],
        'Puzzles': [
            {
                topic: '\"Into the Future\"',
                theme: 'Words associated with future technologies and sci-fi concepts (without complex jargon).',
                examples: 'Robot, Galaxy, Virtual, Quantum, Portal, Vision, Gadget, Future'
            },
        ],
    },
]