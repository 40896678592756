import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DelayedLink } from '../../../hooks/DelayedLink';
import { classes } from '../../../models/themes';
import { name_text_white_png, next_icon_png, prev_icon_png, unclickable_next_icon_png, unclickable_prev_icon_png } from '../../../assets';
import ExpandableContainer from '../ExpandableContainer';
import './mobileSyllabus.css'
import { bounceContainerById } from '../../../animations/BounceComponent';

const MobileSyllabus = () => {

    function setMiddleRowHeight() {
        const totalVh = window.innerHeight * 0.01; // Represents 1vh
        const topRowHeight = 10; // in vh
        const bottomRowHeight = 25; // in vh

        const middleRowHeight = (window.innerHeight / totalVh) - topRowHeight - bottomRowHeight;

        // Set the custom CSS property for middle row height
        document.documentElement.style.setProperty('--middle-row-height', middleRowHeight + 'vh');
    }

    // Listen for the load and resize events
    window.addEventListener('load', setMiddleRowHeight);
    window.addEventListener('resize', setMiddleRowHeight);

    const { className } = useParams();
    const classNumber = className.match(/\d+/)[0];
    useEffect(() => {
        document.title = `Sentence Architect - Class ${classNumber} - Syllabus`
    }, []);
    const handleClassChange = (newClassId) => {
        // Replace current URL without adding to the history stack
        navigate(`/syllabus/class-${newClassId}`, { replace: true });
    };

    const navigate = useNavigate();

    const choosenClass = classes[classNumber - 1];
    // const choosenClass = location.state?.classDetail || sessionStorage.getItem('currentClassID') ? classes[sessionStorage.getItem('currentClassID')] : classes[0];

    const [currentClass, setCurrentClass] = useState(choosenClass);
    const [openedContainers, setOpenedContainers] = useState({ 1: false, 2: false, 3: false, 4: false });

    useEffect(() => {
        // Triggered every time the category changes
        setOpenedContainers({ 1: false, 2: false, 3: false, 4: false }); // Close all containers initially

        const timer = setTimeout(() => {
            setOpenedContainers({ 1: true, 2: true, 3: true, 4: true }); // Open all containers after a delay
        }, 300);

        return () => clearTimeout(timer);
    }, [currentClass]);

    const handleButtonClick = (containerId) => {
        setOpenedContainers({ 1: false, 2: false, 3: false, 4: false }); // Close all containers

        setTimeout(() => {
            setOpenedContainers(prev => ({ ...prev, [containerId]: !prev[containerId] }))
        }, 300);
    }
    let timeoutId; // Variable to store the timeout ID
    function addAndRemoveAnimationClass(direction) {
        const element = document.getElementById("MobileClassControls");
    
        if (timeoutId) {
            clearTimeout(timeoutId); // Clear the existing timeout if it exists.
        }
    
        if (direction === 'prev') {
            element.classList.add('prev-background-animation');
    
            // Set a timeout to remove the class after 500 milliseconds.
            timeoutId = setTimeout(() => {
                element.classList.remove('prev-background-animation');
                timeoutId = null; // Clear the timeoutId since it has completed.
            }, 500);
        } else {
            element.classList.add('next-background-animation');
    
            // Set a timeout to remove the class after 500 milliseconds.
            timeoutId = setTimeout(() => {
                element.classList.remove('next-background-animation');
                timeoutId = null; // Clear the timeoutId since it has completed.
            }, 500);
        }
    }

    const handleClassClick = (class_obj, bounceId, direction) => {
        if (class_obj.id !== currentClass.id) {
            setCurrentClass(classes[class_obj.id - 1]);
            sessionStorage.setItem('currentClassID', class_obj.id - 1);
            bounceContainerById(bounceId)
            handleClassChange(class_obj.id)
        }
        addAndRemoveAnimationClass(direction);
    };


    return (
        <div className="mobile-syllabus open">
            <div className="mobile-syllabus-content">

                <div className='mobile-top-row'>
                    <div className='mobile-text-logo'>
                        <img src={name_text_white_png} className='mobile-text-logo-img' alt="Sentence Architect Name Text Image" fetchpriority="high"></img>
                    </div>
                </div>
                <div className='mobile-syllabus-bottom-container'>
                    <ExpandableContainer
                        isMobile={true}
                        type='Grammar'
                        classNumber={currentClass.id}
                        isOpen={openedContainers[1]}
                        onExternalControl={() => setOpenedContainers(prev => ({ ...prev, 1: !prev[1] }))}
                    />
                    <ExpandableContainer
                        isMobile={true}
                        type='Reading'
                        classNumber={currentClass.id}
                        isOpen={openedContainers[2]}
                        onExternalControl={() => setOpenedContainers(prev => ({ ...prev, 2: !prev[2] }))}
                    />
                    <ExpandableContainer
                        isMobile={true}
                        type='Writing'
                        classNumber={currentClass.id}
                        isOpen={openedContainers[3]}
                        onExternalControl={() => setOpenedContainers(prev => ({ ...prev, 3: !prev[3] }))}
                    />
                    <ExpandableContainer
                        isMobile={true}
                        type='Puzzles'
                        classNumber={currentClass.id}
                        isOpen={openedContainers[4]}
                        onExternalControl={() => setOpenedContainers(prev => ({ ...prev, 4: !prev[4] }))}
                    />
                </div>
                <div className='mobile-controls-row'>
                    <div className='mobile-syllabus-controls'>
                        <div className='mobile-syllabus-title'>
                            Class {currentClass.id} - Syllabus
                        </div>
                        <div className='mobile-syllabus-topic-buttons'>
                            <div className='mobile-syllabus-topic-button' onClick={() => handleButtonClick(1)}><span style={{ pointerEvents: 'none' }}>Grammar</span></div>
                            <div className='mobile-syllabus-topic-button' onClick={() => handleButtonClick(2)}><span style={{ pointerEvents: 'none' }}>Reading</span></div>
                            <div className='mobile-syllabus-topic-button' onClick={() => handleButtonClick(3)}><span style={{ pointerEvents: 'none' }}>Writing</span></div>
                            <div className='mobile-syllabus-topic-button' onClick={() => handleButtonClick(4)}><span style={{ pointerEvents: 'none' }}>Puzzles</span></div>
                        </div>
                    </div>
                    <div className='mobile-class-controls' id='MobileClassControls'>
                        <div className='mobile-class-control-prev-button'>
                            <img src={currentClass.id == 1 ? unclickable_prev_icon_png : prev_icon_png} id='Mobile-PrevClassSyllabusButton' alt="Previous Class Button" onClick={() => currentClass.id == 1 ? null : handleClassClick(classes[currentClass.id - 2], 'Mobile-PrevClassSyllabusButton', 'prev')} />
                        </div>
                        <div className='mobile-class-control-class-display'>
                            <div>
                                Class<br />
                            </div>

                            <div className='mobile-class-control-class-display-number'>
                                {currentClass.id}
                            </div>
                        </div>
                        <div className='mobile-class-control-next-button'>
                            <img src={currentClass.id == 12 ? unclickable_next_icon_png : next_icon_png} id='Mobile-NextClassSyllabusButton' alt="Next Class Button" onClick={() => currentClass.id == 12 ? null : handleClassClick(classes[currentClass.id], 'Mobile-NextClassSyllabusButton', 'next')} />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default MobileSyllabus