import React, { useState, useEffect } from 'react';
import { useViewPortMobileCheck } from '../../../../hooks/ViewPortContext';
import { double_arrow_black_icon_png, double_arrow_white_icon_png } from '../../../../assets';
import './scrollButton.css';

const ScrollButton = (props) => {
    const isMobile = useViewPortMobileCheck();
    const [isRotated, setIsRotated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsRotated(document.getElementById(props.id).scrollTop >= 5);
        };

        const onPageLoad = () => {
            const pageContents = document.getElementById(props.id);
            if (pageContents) {
                pageContents.addEventListener('scroll', handleScroll);
            }
        };

        const cleanup = () => {
            const pageContents = document.getElementById(props.id);
            if (pageContents) {
                pageContents.removeEventListener('scroll', handleScroll);
            }
        };

        if (document.readyState === "complete") {
            onPageLoad();
            return cleanup;
        } else {
            window.addEventListener("load", onPageLoad);
            return () => {
                window.removeEventListener("load", onPageLoad);
                cleanup();
            };
        }
    }, [isMobile]);

    const scrollToTop = () => {
        document.getElementById(props.id).scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const scrollToThemes = () => {
        if (props.id === "LandingContents") {
            const container = document.getElementById('LandingContents');
            const element = document.getElementById(isMobile ? "ThemesMobile" : "ThemesDesktop");
            const offset = isMobile ? 6 : 4; // Set the desired offset value
            const multiplier = isMobile ? window.innerHeight : window.innerWidth;

            if (element) {
                const containerRect = container.getBoundingClientRect();
                const elementRect = element.getBoundingClientRect();
                const scrollOffset = (elementRect.top - containerRect.top) - (offset * multiplier / 100);

                container.scrollTop += scrollOffset;
            }
        }
        else {
            document.getElementById(props.id).scrollTo({
                top: document.getElementById(props.id).scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    const positionStyle = {
        bottom: isMobile ? props.id === "LandingContents" ? '2.5vh' : '12.5vh' : '2vw',
        right: isMobile ? '2.5vh' : '2vw',
        width: isMobile ? '5vh' : '3.5vw',
        height: isMobile ? '5vh' : '3.5vw',
        border: isMobile ? '0.75vw solid #000000' : '0.25vw solid #000000',
        backgroundColor: props.id === "LandingContents" ? (isRotated ? '#4c4c4c' : '#ffffff') : isRotated ? '#1e4941' : props.color,
    }

    const arrowStyle = {
        height: isMobile ? '2.5vh' : '1.5vw',
    }

    return (
        <div
            className={`scroll-button ${isRotated ? 'up' : props.id === "LandingContents" ? 'down jump' : 'down'} disable-select`}
            onClick={isRotated ? scrollToTop : scrollToThemes}
            style={positionStyle}
        >
            <img className="double-arrow-up" aria-hidden="true" src={isRotated ? double_arrow_white_icon_png : double_arrow_black_icon_png} style={arrowStyle}></img>
        </div>
    );
};

export default ScrollButton;
