import React from 'react';
import { Link } from 'react-router-dom';
import { classes } from '../../../../../models/themes';
import ImageLoader from '../../../../../utils/ImageLoader';
import './desktopThemes.css'
import '../themes.css'

const DesktopThemes = () => {
    const titleStyleDesktop = {
        fontSize: '4vw',
        margin: '0',
        marginTop: '2vw',
        marginBottom: '3vw',
        padding: '0'
    }
    
    const handleClassClick = (class_obj) => {
        sessionStorage.setItem('currentClassID', class_obj.id - 1);
    }

    return (
        <div className="themes" id='ThemesDesktop'>
            <div className="themes-content desktop-themes-content">
                <h2 className="themes-title desktop-themes-title" style={titleStyleDesktop}>Exam Syllabus</h2>
                <div className='all-themes-container'>
                    {classes.map(class_obj => (
                        <Link
                            key={class_obj.id}
                            className={`single-theme-category-container zoom-effect`}
                            id={`CategoryContainer${class_obj.id}`}
                            style={{ backgroundColor: class_obj.color, '--zoomDepth': 'zoomIn', textDecoration: 'none', cursor: 'pointer' }}
                            onClick={ () => handleClassClick(class_obj)}
                            to={`/syllabus/class-${class_obj.id}`}
                        >
                            <div className='theme-category-text theme-category-grid-item'>
                                <div className='theme-category-title'>
                                    Class
                                </div>
                                <div className='theme-category-title-class-number'>
                                    {class_obj.id}
                                </div>

                            </div>
                            <div className='theme-category-image theme-category-grid-item'>
                                <ImageLoader
                                    baseID={`DesktopCategoryImage${class_obj.id}`}
                                    className={'theme-category-image-content'}
                                    allRes_png={class_obj.imagePng}
                                    allRes_webp={class_obj.imageWebp}
                                    fetchPriority={'low'}
                                />
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div >
    )
}

export default DesktopThemes