import React from 'react';
import { useViewPortMobileCheck } from '../../../hooks/ViewPortContext';
import DesktopGuidelines from './desktop/DesktopGuidelines';
import MobileGuidelines from './mobile/MobileGuidelines';

const Guidelines = () => {
    const isMobile = useViewPortMobileCheck()

    return isMobile
    ? (
        <MobileGuidelines />
    )
    : (
        <DesktopGuidelines />
    )
}

export default Guidelines