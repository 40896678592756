import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ViewportProvider } from './hooks/ViewPortContext';

import { LandingPage, SyllabusPage, SyllabusPageRedirect, GuidelinesPage, TnCPage } from './pages';

const App = () => {
    // document.addEventListener('contextmenu', (e) => {
    //     e.preventDefault();
    //   });
    return (
        <ViewportProvider>
            <BrowserRouter>

                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/syllabus" element={<SyllabusPageRedirect />} />
                    <Route path="/syllabus/:className" element={<SyllabusPage />} />
                    <Route path="/guidelines" element={<GuidelinesPage />} />
                    <Route path="/tnc" element={<TnCPage />} />
                </Routes>

            </BrowserRouter>
        </ViewportProvider>
    )
}

export default App