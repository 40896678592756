import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SyllabusPageRedirect() {
    const navigate = useNavigate();

    useEffect(() => {
        // Define the default className to redirect to, e.g., 'class-1'.
        navigate('/syllabus/class-1');
    }, [navigate]);

    return null;  // Render nothing.
}

export default SyllabusPageRedirect;
