import React from 'react';
import { useViewPortMobileCheck } from '../../../../hooks/ViewPortContext';
import DesktopAwards from './desktop/DesktopAwards';
import MobileAwards from './mobile/MobileAwards';

const Awards = () => {
    const isMobile = useViewPortMobileCheck()

    return isMobile
    ? (
        <MobileAwards />
    )
    : (
        <DesktopAwards />
    )
}

export default Awards