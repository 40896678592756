import React, { useContext, useState, useEffect } from "react";

const ViewportContext = React.createContext({});

export function useViewPort() {
    const { width, height } = useContext(ViewportContext);
    return { width, height };
};

export function useViewPortMobileCheck(customRatio) {
    const { width, height } = useContext(ViewportContext);
    return customRatio != null ? width/height < customRatio ? true : false
    : width/height < 1 ? true : false;
};

export function useViewPortRatio(customRatio) {
    const { width, height } = useContext(ViewportContext);
    return width/height;
};


export function ViewportProvider({ children }) {

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    function handleWindowResize() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <ViewportContext.Provider value={{ width, height }}>
            {children}
        </ViewportContext.Provider>
    );
};