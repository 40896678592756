import { useViewPortMobileCheck } from '../../../../hooks/ViewPortContext';
import { name_text_white_png, close_icon_png } from '../../../../assets';
import './sidebar.css'
import { useNavigate } from 'react-router-dom';

const SideBar = () => {
    const isMobile = useViewPortMobileCheck();
    const scrollToSection = (sectionId) => {
        const container = document.getElementById('LandingContents');
        const element = document.getElementById(sectionId);
        const offset = 10; // Set the desired offset value

        if (element) {
            const containerRect = container.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            const scrollOffset = (elementRect.top - containerRect.top) - (offset * window.innerHeight / 100);

            container.scrollTop += scrollOffset;
        }
        toggleSideDrawer();
    };

    const navigate = useNavigate();

    const handleNavigation = (page) => {
        navigate(`/${page}`);
    };

    const toggleSideDrawer = () => {

        document.getElementById('SideDrawer').classList.remove('open');
    }

    return isMobile ? (
        <div className='landingPage-side-drawer' id='SideDrawer'>
            <div className='landingPage-navbar-side-bar' >
                <div className='mobile-landingPage-sidebar-logo'>
                    <img src={name_text_white_png} alt="Sentence Architect Name Text Image"></img>
                </div>
                <div className='landingPage-navbar-links-mobile'>
                    <div className='landingPage-side-drawer-close-icon' onClick={() => toggleSideDrawer()}>
                        <img src={close_icon_png} alt="Side Bar Close Icon"></img>
                    </div>
                </div>
            </div>
            <div className='landingPage-navbar-links-container-mobile'>
                <div className='landingPage-navbar-links-container-box-mobile black-box' onClick={() => { scrollToSection("ThemesMobile"); }}>
                    <div className='landingPage-navbar-links-container-text-mobile-box'>
                        <div className='landingPage-navbar-links-container-text-mobile-box-text' id='themesNavItem-mobile'>
                            Syllabus
                        </div>
                    </div>
                </div>
                <div className='landingPage-navbar-links-container-box-mobile gold-box' onClick={() => { scrollToSection("AwardsMobile"); }}>
                    <div className='landingPage-navbar-links-container-text-mobile-box'>
                        <div className='landingPage-navbar-links-container-text-mobile-box-text' id='awardsNavItem-mobile'>
                            Awards
                        </div>
                    </div>
                </div>
            </div>
            <div className='landingPage-navbar-links-container-mobile mobile-sidebar-bottom'>
                <div className='landingPage-navbar-links-container-box-mobile normal-box' onClick={() => { handleNavigation("guidelines") }}>
                    <div className='landingPage-navbar-links-container-text-mobile-box'>
                        <div className='landingPage-navbar-links-container-text-mobile-box-text' id='guidelinesNavItem-mobile'>
                            Guidelines
                        </div>
                    </div>
                </div>
                <div className='landingPage-navbar-links-container-box-mobile normal-box' onClick={() => { handleNavigation("tnC") }}>
                    <div className='landingPage-navbar-links-container-text-mobile-box'>
                        <div className='landingPage-navbar-links-container-text-mobile-box-text' id='tnCNavItem-mobile'>
                            Terms and Conditions
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (<></>);
}

export default SideBar