import './navbar.css'
import { sentence_architect_logo, name_text_black_png, menu_icon_png } from '../../../../assets';

const MobileNavBar = () => {

    const toggleSideDrawer = () => {
        document.getElementById('SideDrawer').classList.add('open');
    }

    return (
        <>
            <div className='mobile-landingPage-logo'>
                <img src={sentence_architect_logo} alt="Sentence Architect Logo" fetchpriority="high"></img>
                <img src={name_text_black_png} className='mobile-landingPage-navbar-name-text-img' alt="Sentence Architect Name Text Image" fetchpriority="high"></img>
            </div>
            <div className='mobile-landingPage-navbar-links'>
                <div className='mobile-landingPage-side-drawer-icon' onClick={() => toggleSideDrawer()}>
                    <img src={menu_icon_png} alt="Side Bar Menu Icon"></img>
                </div>
            </div>
        </>

    )
}

export default MobileNavBar