import React from 'react';
import './tncPage.css'
import { TermsNConditions } from './containers';

const TnCPage = () => {
    return (
        <div className='TnCPage'>
            <TermsNConditions/>
        </div>
    )
}

export default TnCPage