import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { DelayedLink } from '../../../../../hooks/DelayedLink';
import { classes } from '../../../../../models/themes';
import '../../../../syllabuspage/desktop/desktopSyllabus.css'
import './mobileThemes.css'
import '../themes.css'
import ImageLoader from '../../../../../utils/ImageLoader';

const MobileThemes = () => {
    const titleStyleMobile = {
        fontSize: '8vw',
        margin: '0',
        marginTop: '0vw',
        marginBottom: '3vh',
        padding: '0'
    }

    const [direction, setDirection] = useState(1); // 1 for right, -1 for left
    const [autoScrolling, setAutoScrolling] = useState(true);
    const scrollContainerRef = useRef(null);
    const animationFrameRef = useRef(null);

    const scrollAutomatically = () => {
        if (!autoScrolling) return;

        const scrollContainer = scrollContainerRef.current;

        if (!scrollContainer) return;

        if (direction === 1 && (scrollContainer.scrollLeft + scrollContainer.offsetWidth) >= scrollContainer.scrollWidth - 5) {
            setDirection(-1);
        } else if (direction === -1 && scrollContainer.scrollLeft <= 0) {
            setDirection(1);
        }

        scrollContainer.scrollLeft += direction * 1;

        animationFrameRef.current = requestAnimationFrame(scrollAutomatically);
    };

    useEffect(() => {
        animationFrameRef.current = requestAnimationFrame(scrollAutomatically);

        return () => {
            cancelAnimationFrame(animationFrameRef.current);
        };
    }, [direction, autoScrolling]);

    const choosenClass = classes[0];

    const [currentClass, setCurrentClass] = useState(choosenClass);

    const handleClassClick = (class_obj) => {
        if (class_obj.id !== currentClass.id) {
            setCurrentClass(classes[class_obj.id - 1]);
        }
    };

    return (
        <div className="themes" id='ThemesMobile'>
            <div className="mobile-themes-content">
                <h2 className="themes-title mobile-themes-title" style={titleStyleMobile}>Exam Syllabus</h2>
                <div className='mobile-expanded-class'>
                    <div
                        key={currentClass.id + "expanded"}
                        className={`mobile-class-container expanded`}
                        id={`ExpandedMobileClassContainer${currentClass.id}`}
                    >
                        <div className='mobile-class-text'>
                            <div className='mobile-class-title'>Class</div>
                            <div className='mobile-class-title-class-number'>
                                {currentClass.id}
                            </div>
                        </div>
                        <div className='mobile-class-image'>
                            <ImageLoader
                                baseID={`MobileClassImage${currentClass.id}`}
                                className={'mobile-box-class-image'}
                                allRes_png={currentClass.imagePng}
                                allRes_webp={currentClass.imageWebp}
                                fetchPriority={'low'}
                            />
                        </div>
                        <DelayedLink delay="200" to={`/syllabus/class-${currentClass.id}`} isBounce={true} divId="Mobile-ClassViewSyllabusButton">
                            <div
                                id='Mobile-ClassViewSyllabusButton'
                                className='mobile-class-view-syllabus-button'
                                style={{ textDecoration: 'none' }}
                                onClick={() => handleClassClick(currentClass)}
                            >
                                View Class {currentClass.id} Syllabus
                            </div>
                        </DelayedLink>

                    </div>
                </div>
                <div className='mobile-classlist-container'>
                    <div className='mobile-classlist-topic'>Choose Class</div>
                    <div className='mobile-classlist-scroll-container' id='MobileClassList' ref={scrollContainerRef}>
                        {classes.map(class_obj => (
                            <div
                                key={class_obj.id}
                                className={`mobile-clickable-class-container`}
                                id={`MobileClickableClassContainer${class_obj.id}`}
                                onClick={() => handleClassClick(class_obj)}
                            >
                                <div className='mobile-clickable-class-text'>
                                    <div className='mobile-clickable-class-title'>Class</div>
                                    <div className='mobile-clickable-class-title-class-number'>
                                        {class_obj.id}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileThemes