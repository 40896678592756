import './bounceComponent.css';

export function bounceContainerById(id) {
    var element = document.getElementById(id);
    resetBounceAnimation(element);
}

export function resetBounceAnimation(element) {
    if (element != null) {
        element.classList.remove("bounce");
        if (element.offsetHeight);
        element.classList.add("bounce");
    }
}

export function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
}

