import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { classes } from '../../../models/themes';
import { name_text_white_png, next_icon_png, prev_icon_png, unclickable_next_icon_png, unclickable_prev_icon_png } from '../../../assets';
import ImageLoader from '../../../utils/ImageLoader';
import ExpandableContainer from '../ExpandableContainer';
import './desktopSyllabus.css'
import { bounceContainerById } from '../../../animations/BounceComponent';

const DesktopSyllabus = () => {

    const { className } = useParams();
    const classNumber = className.match(/\d+/)[0];
    useEffect(() => {
        document.title = `Sentence Architect - Class ${classNumber} - Syllabus`
    }, [classNumber]);
    const handleClassChange = (newClassId) => {
        // Replace current URL without adding to the history stack
        navigate(`/syllabus/class-${newClassId}`, { replace: true });
    };
    
    const navigate = useNavigate();

    const choosenClass = classes[classNumber - 1];
    // const choosenClass = location.state?.classDetail || sessionStorage.getItem('currentClassID') ? classes[sessionStorage.getItem('currentClassID')] : classes[0];

    const [currentClass, setCurrentClass] = useState(choosenClass);
    const [scrollTriggeredClassId, setScrollTriggeredClassId] = useState(null);
    const scrollTimeoutRef = useRef(null);
    const centeringTimeoutRef = useRef(null);

    const [openedContainers, setOpenedContainers] = useState({ 1: false, 2: false, 3: false, 4:false });

    useEffect(() => {
        scrollBasedOnClass(currentClass);
    }, []);

    const handleModalClassClick = (class_obj, bounceId) => {
        if (class_obj.id === currentClass.id) {
            return;
        }
        else {
            setCurrentClass(classes[class_obj.id - 1]);
            sessionStorage.setItem('currentClassID', class_obj.id - 1);
            handleClassChange(class_obj.id)
            setScrollTriggeredClassId(null);
            scrollBasedOnClass(classes[class_obj.id - 1]);
        }
        bounceContainerById(bounceId)
    };

    const smoothScrollTo = (element, target, duration) => {
        const start = element.scrollTop;
        const change = target - start;
        const startTime = performance.now();

        const easeInOutQuad = (t) => {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        };

        const animateScroll = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = easeInOutQuad(Math.min(elapsed / duration, 1));

            element.scrollTop = start + progress * change;

            if (elapsed < duration) {
                requestAnimationFrame(animateScroll);
            }
        };

        requestAnimationFrame(animateScroll);
    };

    const calculateHeightFromAspectRatio = (element, ratio = 1) => {
        const width = element.offsetWidth;
        return width / ratio;
    };

    const centerElementInView = (element, container, expanded = false, byScrollAction = false) => {
        const ratio = expanded ? 1 : 4; // 1:1 for expanded, 4:1 for unexpanded
        const boxHeight = calculateHeightFromAspectRatio(element, ratio);
        const containerVisibleHeight = container.clientHeight;
        expanded ? smoothScrollTo(container, element.offsetTop - (3 * containerVisibleHeight / 7.35), byScrollAction ? 600 : 300)
            : container.scrollTop = element.offsetTop - (containerVisibleHeight / 2) - (boxHeight / 2);
    };

    const scrollBasedOnClass = (newClass, byScrollAction = false) => {
        if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current);
        }
        if (centeringTimeoutRef.current) {
            clearTimeout(centeringTimeoutRef.current);
        }
        const container = document.getElementById("ModalClassList");
        const targetIndex = classes.indexOf(newClass);

        if (targetIndex === 11) {
            container.scrollTop = container.scrollHeight;

            centeringTimeoutRef.current = setTimeout(() => {
                container.scrollTop = container.scrollHeight;
            }, 1100);
        } else if (targetIndex > 0) {
            const currentBox = document.getElementById(`ClassContainer${classes[targetIndex].id}`);
            if (currentBox && byScrollAction) {
                centerElementInView(currentBox, container); // by default, this will center the unexpanded box
            }
        } else {
            container.scrollTop = 0;
        }

        centeringTimeoutRef.current = setTimeout(() => {
            const expandedBox = document.getElementById(`ClassContainer${newClass.id}`);
            if (expandedBox) {
                centerElementInView(expandedBox, container, true, true); // setting expanded to true
            }
        }, byScrollAction ? 1100 : 350);

        if (byScrollAction) {
            const delay = 800;
            scrollTimeoutRef.current = setTimeout(() => {
                setScrollTriggeredClassId(null);
            }, delay);
        }
    };


    useEffect(() => {
        let accumulatedDeltas = [];
        let deltaTimeout;
        const lastScrollTime = { current: null }; // Using an object for mutability
        let scrollLocked = false;

        const computeAverageAndScroll = () => {
            const avgDelta = accumulatedDeltas.reduce((acc, curr) => acc + curr, 0) / accumulatedDeltas.length;

            setCurrentClass(prev => {
                const currentIndex = classes.indexOf(prev);
                let newClass = prev; // default value

                if (avgDelta > 0) {
                    const nextIndex = Math.min(classes.length - 1, currentIndex + 1);
                    newClass = classes[nextIndex];
                } else if (avgDelta < 0) {
                    const prevIndex = Math.max(0, currentIndex - 1);
                    newClass = classes[prevIndex];
                }
                sessionStorage.setItem('currentClassID', newClass.id - 1);
                handleClassChange(newClass.id)
                scrollBasedOnClass(newClass, true); // Handle the scrolling based on the new class
                setScrollTriggeredClassId(newClass.id);
                return newClass;
            });

            accumulatedDeltas = []; // Reset deltas
        }

        const onWheel = (e) => {
            e.preventDefault();
            const now = Date.now();

            if (now - lastScrollTime.current < 200 || scrollLocked) return;

            lastScrollTime.current = now;
            scrollLocked = true;

            accumulatedDeltas.push(e.deltaY);

            if (deltaTimeout) clearTimeout(deltaTimeout);
            deltaTimeout = setTimeout(computeAverageAndScroll, 40);

            setTimeout(() => {
                scrollLocked = false;
            }, 50);
        };

        const onPageLoad = () => {
            const container = document.getElementById("ModalClassList");
            if (!container) return;
            container.addEventListener('wheel', onWheel);
        };

        const cleanup = () => {
            const container = document.getElementById("ModalClassList");
            if (container) {
                container.removeEventListener('wheel', onWheel);
            }
            if (deltaTimeout) clearTimeout(deltaTimeout);
        };

        if (document.readyState === "complete") {
            onPageLoad();
            return cleanup;
        } else {
            window.addEventListener("load", onPageLoad);
            return () => {
                window.removeEventListener("load", onPageLoad);
                cleanup();
            };
        }

    }, []);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
                e.preventDefault();
                if (currentClass.id <= 11) {
                    setCurrentClass(classes[currentClass.id]);
                    sessionStorage.setItem('currentClassID', currentClass.id);
                    handleClassChange(currentClass.id + 1)
                    scrollBasedOnClass(classes[currentClass.id])
                }
            }
            else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
                e.preventDefault();
                if (currentClass.id > 1) {
                    setCurrentClass(classes[currentClass.id - 2]);
                    sessionStorage.setItem('currentClassID', currentClass.id - 2);
                    handleClassChange(currentClass.id - 1)
                    scrollBasedOnClass(classes[currentClass.id - 2])
                }
            }

        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            // Cleanup event listener on component unmount
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentClass]);

    useEffect(() => {
        // Triggered every time the category changes
        setOpenedContainers({ 1: false, 2: false, 3: false, 4:false }); // Close all containers initially

        const timer = setTimeout(() => {
            setOpenedContainers({ 1: true, 2: true, 3: true, 4:true }); // Open all containers after a delay
        }, !scrollTriggeredClassId ? 300 : 750);

        return () => clearTimeout(timer);
    }, [currentClass]);

    const handleButtonClick = (containerId) => {
        setOpenedContainers({ 1: false, 2: false, 3: false, 4:false }); // Close all containers

        setTimeout(() => {
            setOpenedContainers(prev => ({ ...prev, [containerId]: !prev[containerId] }))
        }, 300);
        // if (containerId == 1) {
        //     setOpenedContainers({ 1: true, 2: false, 3: false });
        // } else if (containerId == 2) {
        //     setOpenedContainers({ 1: false, 2: true, 3: false });
        // } else {
        //     setOpenedContainers({ 1: false, 2: false, 3: true });
        // }

    }

    return (

        <div className="desktop-themes-modal open">
            <div className="desktop-themes-modal-content">

                <div className='modal-top-row'>
                    <div className='desktop-modal-logo'>
                        <img src={name_text_white_png} className='desktop-modal-logo-img' alt="Sentence Architect Name Text Image" fetchpriority="high"></img>
                    </div>
                    <div/>
                    <div className='modal-class-controls'>
                                <div className='modal-class-control-prev-button'>
                                    <img src={currentClass.id == 1 ? unclickable_prev_icon_png : prev_icon_png} id='Desktop-PrevClassSyllabusButton' alt="Previous Class Button" onClick={() => currentClass.id == 1 ? null : handleModalClassClick(classes[currentClass.id - 2], 'Desktop-PrevClassSyllabusButton')} />
                                </div>
                                <div className='modal-class-control-class-display'>
                                    <div>
                                        Class<br />
                                    </div>

                                    <div className='modal-class-control-class-display-number'>
                                        {currentClass.id}
                                    </div>
                                </div>
                                <div className='modal-class-control-next-button'>
                                    <img src={currentClass.id == 12 ? unclickable_next_icon_png : next_icon_png} id='Desktop-NextClassSyllabusButton' alt="Next Class Button" onClick={() => currentClass.id == 12 ? null : handleModalClassClick(classes[currentClass.id], 'Desktop-NextClassSyllabusButton')} />
                                </div>
                            </div>
                </div>
                <div className='modal-content-row'>
                    <div className='modal-classes-list' id='ModalClassList'>
                        {classes.map(class_obj => (
                            <div
                                key={class_obj.id}
                                className={`class-container ${currentClass.id == class_obj.id ? "expanded" : ""} ${scrollTriggeredClassId == class_obj.id ? "scroll-triggered" : ""}`}
                                id={`ClassContainer${class_obj.id}`}
                                onClick={() => handleModalClassClick(class_obj)}
                            >
                                <div className='class-text'>
                                    <div className='class-title'>Class</div>
                                    <div className='class-title-class-number'>
                                        {class_obj.id}
                                    </div>
                                </div>
                                {currentClass.number == class_obj.number ?
                                    <div className='class-image'>
                                        <ImageLoader
                                            baseID={`DesktopModalClassImage${class_obj.id}`}
                                            className={'modal-class-image'}
                                            allRes_png={class_obj.imagePng}
                                            allRes_webp={class_obj.imageWebp}
                                            fetchPriority={'high'}
                                        />
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        ))}
                        <div className='class-container-dummy'></div>
                    </div>
                    <div className='modal-syllabus-container'>
                        <div className='modal-syllabus-top-section'>
                            <div className='modal-syllabus-controls'>
                                <div className='modal-syllabus-title'>
                                    Class {currentClass.id} - Syllabus
                                </div>
                                <div className='modal-syllabus-topic-buttons'>
                                    <div className='syllabus-topic-button' onClick={() => handleButtonClick(1)}>Grammar</div>
                                    <div className='syllabus-topic-button' onClick={() => handleButtonClick(2)}>Reading</div>
                                    <div className='syllabus-topic-button' onClick={() => handleButtonClick(3)}>Writing</div>
                                    <div className='syllabus-topic-button' onClick={() => handleButtonClick(4)}>Puzzles</div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-syllabus-bottom-container'>
                            <ExpandableContainer
                                type='Grammar'
                                classNumber={currentClass.id}
                                isOpen={openedContainers[1]}
                                onExternalControl={() => setOpenedContainers(prev => ({ ...prev, 1: !prev[1] }))}
                            />
                            <ExpandableContainer
                                type='Reading'
                                classNumber={currentClass.id}
                                isOpen={openedContainers[2]}
                                onExternalControl={() => setOpenedContainers(prev => ({ ...prev, 2: !prev[2] }))}
                            />
                            <ExpandableContainer
                                type='Writing'
                                classNumber={currentClass.id}
                                isOpen={openedContainers[3]}
                                onExternalControl={() => setOpenedContainers(prev => ({ ...prev, 3: !prev[3] }))}
                            />
                            <ExpandableContainer
                                type='Puzzles'
                                classNumber={currentClass.id}
                                isOpen={openedContainers[4]}
                                onExternalControl={() => setOpenedContainers(prev => ({ ...prev, 4: !prev[4] }))}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DesktopSyllabus