import React, { useState, useEffect } from 'react';
import './expandableContainer.css'; // Assuming you have some CSS for styling
import { classes, patterns } from '../../models/themes';
import { expand_icon_png } from '../../assets';

const ExpandableContainer = ({ isMobile, type, classNumber, isOpen, onExternalControl }) => {
    const [localExpanded, setLocalExpanded] = useState(false);
    const isExpanded = onExternalControl !== undefined ? isOpen : localExpanded;

    useEffect(() => {
        if (onExternalControl !== undefined && isOpen !== isExpanded) {
            setLocalExpanded(isOpen);
        }
    }, [isOpen, onExternalControl, isExpanded]);

    const handleTitleClick = () => {
        if (onExternalControl !== undefined) {
            onExternalControl();
        } else {
            setLocalExpanded(prev => !prev);
        }
    }

    const isPrimaryClass = (classNumber) => {
        return classNumber <= 5;
    }

    return (
        <div className={`syllabus-container ${isExpanded ? 'expanded' : ''} ${isMobile ? 'mobile-expandable-container' : ''}`}>
            <div className='syllabus-type' onClick={handleTitleClick}>
                <div className='syllabus-type-title'>{type}</div>
                <img className='syllabus-type-expand' alt="Expand Syllabus Button" src={expand_icon_png} />
            </div>
            <div className='syllabus-details'>
                <div className='pattern-details'>
                    <div className='pattern-type'>
                        <div className='pattern-left-name'>Pattern: </div>
                        <div className='pattern-right-description'>
                            { type != "Grammar" 
                        ? patterns[type][0].pattern 
                        : patterns[type][0].pattern[Math.floor((classNumber - 1) / 8)]
                        }</div>
                    </div>
                    {type == "Writing" &&
                        <div className='pattern-type'>
                            <div className='pattern-left-name'>Word Limit: </div>
                            <div className='pattern-right-description'>{patterns[type][0].wordLimit[classNumber - 1]}
                            </div>
                        </div>
                    }
                    <div className='pattern-type'>
                        <div className='pattern-left-name'>Time Limit: </div>
                        <div className='pattern-right-description'>
                            {patterns[type][0].time} mins
                        </div>
                    </div>
                </div>
                {type == "Grammar" && <h2 style={{ fontSize: isMobile ? '2.5vh' : '', paddingBottom: isMobile ? '0' : '' }}>
                    Grammar Topics:
                </h2>}
                {classes[classNumber - 1][type].map((item, index) => (
                    <div key={index}>
                        {type == "Puzzles" &&
                            <>
                                <div className='syllabus-puzzles-detail'>
                                    <h2>Crossword Puzzle</h2>
                                    <h2>Topic: {item.topic}</h2>
                                    <h2>Theme:</h2><p>{item.theme}</p>
                                    <h2>Examples:</h2><p>{item.examples}</p>
                                </div>
                                <div className='syllabus-puzzles-detail'>
                                    <h2>Word Play Puzzles</h2>
                                    <h2>Quetion Types: </h2><p>Anagram Discovery, Synonym Discovery, Homophones Discovery</p>
                                </div>
                            </>
                        }
                        {type == "Writing" &&
                            <>
                                <div className='syllabus-writing-detail-top'>
                                    <h2>Option 1:</h2><p>{item.option1}</p>
                                </div>
                                <div className='syllabus-writing-detail-top'>
                                    <h2>Option 2:</h2><p>{item.option2}</p>
                                </div>
                                <div className='syllabus-writing-detail-bottom'>
                                    <h2>Option 3:</h2><p>{item.option3}</p>
                                </div>
                            </>
                        }
                        {type == "Reading" &&
                            <div className='syllabus-reading-detail'>
                                <h2>Text Genre:</h2><p>{item.textGenre}</p>
                            </div>
                        }
                        {isPrimaryClass(classNumber) && type == "Grammar" &&
                            <div className='syllabus-only-topic'>
                                <h4 style={{ fontWeight: 500 }}>{index + 1}.</h4>
                                <h4>{item.topic}</h4>
                            </div>
                        }
                        {!isPrimaryClass(classNumber) && type == "Grammar" &&
                            <div className='syllabus-one-detail'>
                                <h2>{item.topic}</h2>
                                {item.description && item.description.map((desc, descIndex) => (
                                    <p key={descIndex}>{desc}</p>
                                ))}
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div >
    );
}

export default ExpandableContainer;
