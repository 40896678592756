import React from 'react';
import './footer.css'
import { useViewPortMobileCheck } from "../../../../hooks/ViewPortContext";
import { sentence_architect_logo, name_text_black_png } from '../../../../assets';
import { DelayedLink } from "../../../../hooks/DelayedLink";

const Footer = () => {
    const isMobile = useViewPortMobileCheck()
    return isMobile
        ? (
            <div className="footer" id='FooterMobile'>
                <div className="footer-top mobile-footer-top">
                    <div className="footer-top-left mobile-footer-top-left">
                        <div className="mobile-footer-logo">
                            <img src={sentence_architect_logo} alt="Sentence Architect Logo" className='mobile-footer-logo-img'></img>
                            <img src={name_text_black_png} alt="Sentence Architect Name Text Image" className='mobile-footer-name-text-img' fetchpriority="high"></img>
                        </div>
                        <div className="mobile-footer-logo-text">
                            A Unit of Topper Class
                        </div>
                    </div>
                    <div className="footer-top-right">
                        <div className="footer-top-right-button">
                            <DelayedLink delay="200" to="/tnc" isBounce={true} divId="Mobile-TnC" className="mobile-footer-terms-button">
                                <div className="mobile-footer-terms-button" id="Mobile-TnC">
                                    <div className='mobile-tnc-container-box'>
                                        Terms & Conditions
                                    </div>
                                </div>
                            </DelayedLink>
                        </div>
                    </div>
                </div>
                <div className="footer-divider" />
                <div className="mobile-footer-bottom">
                    <div className="mobile-footer-bottom-line">
                        {'\u00a9'} 2023 Sentence Architect.
                    </div>
                    <div className="mobile-footer-bottom-line">
                        All Rights Reserved.
                    </div>

                </div>
            </div>
        )
        : (
            <div className="footer" id='FooterDesktop'>
                <div className="footer-top">
                    <div className="footer-top-left">
                        <div className="footer-logo">
                            <img src={sentence_architect_logo} alt="Sentence Architect Logo" className='footer-logo-img'></img>
                            <img src={name_text_black_png} alt="Sentence Architect Name Text Image" className='footer-name-text-img' fetchpriority="high"></img>
                        </div>
                        <div className="footer-logo-text">
                            A Unit of Topper Class
                        </div>
                    </div>
                    <div className="footer-top-right">
                        <div className="footer-top-right-button">
                            <DelayedLink delay="200" to="/tnc" isBounce={true} divId="Desktop-TnC" className="footer-terms-button">
                                <div className="footer-terms-button" id="Desktop-TnC">
                                <div className='tnc-container-box'>
                                        Terms & Conditions
                                    </div>
                                </div>
                            </DelayedLink>
                        </div>
                    </div>
                </div>
                <div className="footer-divider" />
                <div className="footer-bottom">
                    {'\u00a9'} 2023  Sentence Architect. All Rights Reserved.
                </div>
            </div>
        )
}

export default Footer