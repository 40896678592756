import React from 'react';
import { useViewPortMobileCheck } from '../../hooks/ViewPortContext';
import DesktopSyllabus from './desktop/DesktopSyllabus';
import MobileSyllabus from './mobile/MobileSyllabus';

const SyllabusPage = () => {
    const isMobile = useViewPortMobileCheck()

    return isMobile
    ? (
        <MobileSyllabus />
    )
    : (
        <DesktopSyllabus />
    )
}

export default SyllabusPage