import React from 'react';
import { useState, useEffect } from 'react';
import { useViewPortMobileCheck } from '../../../../hooks/ViewPortContext';
import './home.css'
import { mobileBackgroundImage_png, mobileBackgroundImage_low_res_png, mobileBackgroundImage_very_low_res_png } from '../../../../assets';
import { backgroundImage_png, backgroundImage_low_res_png, backgroundImage_very_low_res_png } from '../../../../assets';
import { mobileBackgroundImage_webp, mobileBackgroundImage_low_res_webp, mobileBackgroundImage_very_low_res_webp } from '../../../../assets';
import { backgroundImage_webp, backgroundImage_low_res_webp, backgroundImage_very_low_res_webp } from '../../../../assets';
import ImageLoader from '../../../../utils/ImageLoader';

const Home = () => {
    const isMobile = useViewPortMobileCheck();

    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    const scrollToSection = (sectionId) => {
        const container = document.getElementById('LandingContents');
        const element = document.getElementById(sectionId);
        const offset = 10; // Set the desired offset value

        if (element) {
            const containerRect = container.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            const scrollOffset = (elementRect.top - containerRect.top) - (offset * window.innerHeight / 100);

            container.scrollTop += scrollOffset;
        }
    };

    return isMobile
        ? (
            <div className='home' id='HomeMobile'>
                <div className='home-contents home-contents-mobile'>
                    <div className='mobile-home-top' >
                        <div className={`background-mobile ${isLoading ? 'loading' : ''}`}>
                            <ImageLoader
                                className={'background-mobile'}
                                baseID={'MobileHomePageBackgroundImage'}
                                allRes_png={[mobileBackgroundImage_very_low_res_png, mobileBackgroundImage_low_res_png, mobileBackgroundImage_png]}
                                allRes_webp={[mobileBackgroundImage_very_low_res_webp, mobileBackgroundImage_low_res_webp, mobileBackgroundImage_webp]}
                                onImageLoad={handleImageLoad}
                                fetchPriority={'high'}
                            />
                            <div className='overlay-content'>
                                <h1 className='mobile-home-title-text tinos-font'>
                                    Sentence<br />Architect
                                </h1>
                                <div className='mobile-home-description-top'>
                                    <h3 style={{ fontSize: '6vw', fontWeight: '600', margin: 0, padding: 0, pointerEvents: 'none' }}>The Multi-School
                                        <br />English Competition.</h3>
                                </div>
                                <h3 className='mobile-home-class-title'>
                                    Class 1 - 12
                                </h3>
                            </div>
                        </div>
                        <div className='mobile-home-bottom'>
                            <div className='mobile-home-buttons'>
                                <div className='mobile-homepage-syllabus-link-container' onClick={() => { scrollToSection("ThemesMobile"); }}>
                                    <span style={{pointerEvents: 'none'}}>View Syllabus</span>
                                </div>
                            </div>
                            <div className='mobile-home-description-bottom'>
                                <div className='mobile-home-description-heading'>English: Your Global Passport</div>
                                <div className='mobile-home-description-point'>
                                    <div className='mobile-home-description-number-circle-outside'>
                                        <div className='mobile-home-description-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='mobile-home-description-number'>1</div>
                                        </div>
                                    </div>
                                    <div className='mobile-home-description-text'>
                                        English serves as a global language, bridging nations and diverse cultures in today's interconnected world.
                                    </div>
                                </div>
                                <div className='mobile-home-description-point'>
                                    <div className='mobile-home-description-number-circle-outside'>
                                        <div className='mobile-home-description-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='mobile-home-description-number'>2</div>
                                        </div>
                                    </div>
                                    <div className='mobile-home-description-text'>
                                        Its significant influence spans various sectors including business, technology, science, art, and diplomacy.
                                    </div>
                                </div>
                                <br />
                                <div className='mobile-home-description-heading'>Why Sentence Architect ?</div>
                                <div className='mobile-home-description-point'>
                                    <div className='mobile-home-description-number-circle-outside'>
                                        <div className='mobile-home-description-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='mobile-home-description-number'>1</div>
                                        </div>
                                    </div>
                                    <div className='mobile-home-description-text'>
                                        This competition highlights and celebrates the vast richness and versatility of the English language.
                                    </div>
                                </div>
                                <div className='mobile-home-description-point'>
                                    <div className='mobile-home-description-number-circle-outside'>
                                        <div className='mobile-home-description-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='mobile-home-description-number'>2</div>
                                        </div>
                                    </div>
                                    <div className='mobile-home-description-text'>
                                        Participants are provided an opportunity to evaluate their proficiency in this universally recognized medium of communication.
                                    </div>
                                </div>
                            </div>
                            <div className='mobile-home-eligibility-venue-text'>
                                <div style={{ fontSize: '3.25vh', fontWeight: '700' }}>
                                    Eligibility:
                                    <br />
                                    <div style={{ fontSize: '2.5vh', fontWeight: '400', paddingTop: '1vh' }}>
                                        Open to all students from Class 1 through Class 12.
                                    </div>
                                </div>
                                <br /><br />
                                <div style={{ fontSize: '3.25vh', fontWeight: '700' }}>
                                    Venue:
                                    <br />
                                    <div style={{ fontSize: '2.5vh', fontWeight: '400', paddingTop: '1vh' }}>
                                        At the respective schools of the participants.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        : (
            <div className='home' id='HomeDesktop'>
                <div className='home-contents home-contents-desktop'>
                    <div className='home-top'>
                        <div className={`background-desktop ${isLoading ? 'loading' : ''}`}>
                        <ImageLoader
                                className={'background-desktop'}
                                baseID={'HomePageBackgroundImage'}
                                allRes_png={[backgroundImage_very_low_res_png, backgroundImage_low_res_png, backgroundImage_png]}
                                allRes_webp={[backgroundImage_very_low_res_webp, backgroundImage_low_res_webp, backgroundImage_webp]}
                                onImageLoad={handleImageLoad}
                                fetchPriority={'high'}
                            />
                            <div className='overlay-content'>
                                <h1 className='home-title-text tinos-font'>
                                    Sentence<br />Architect
                                </h1>
                                <div className='home-title-subtext'>
                                    <h3 style={{ fontSize: '1.25vw', fontWeight: '700', margin: 0, padding: 0 }}><i>The Multi-School English Competition.</i></h3>
                                </div>
                                <div className='home-topics-set top'>
                                    <h4 className='home-topic-name-box left'>Reading</h4>
                                    <h4 className='home-topic-name-box right'>Grammar</h4>
                                </div>
                                <div className='home-topics-set bottom'>
                                    <h4 className='home-topic-name-box left'>Writing</h4>
                                    <h4 className='home-topic-name-box right'>Puzzles</h4>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='home-bottom'>
                        <div className='home-eligibility-venue-text'>
                            <span style={{ fontSize: '2.25vw', fontWeight: '900' }}>
                                Eligibility:
                                <br />
                                <span style={{ fontSize: '1.55vw', fontWeight: '500' }}>
                                    Open to students from Class 1 through Class 12.
                                </span>
                            </span>
                            <br /><br />
                            <span style={{ fontSize: '2.25vw', fontWeight: '900' }}>
                                Venue:
                                <br />
                                <span style={{ fontSize: '1.55vw', fontWeight: '500' }}>
                                    At the respective schools of the participants.
                                </span>
                            </span>
                        </div>
                        <div className='home-description-bottom'>
                            <div className='home-description-point-set'>
                                <div className='home-description-heading'>English: Your Global Passport</div>
                                <div className='home-description-point'>
                                    <div className='home-description-number-circle-outside'>
                                        <div className='home-description-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='home-description-number'>1</div>
                                        </div>
                                    </div>
                                    <div className='home-description-text'>
                                        English serves as a global language, bridging nations and diverse cultures in today's interconnected world.
                                    </div>
                                </div>
                                <div className='home-description-point'>
                                    <div className='home-description-number-circle-outside'>
                                        <div className='home-description-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='home-description-number'>2</div>
                                        </div>
                                    </div>
                                    <div className='home-description-text'>
                                        Its significant influence spans various sectors including business, technology, science, art, and diplomacy.
                                    </div>
                                </div>
                            </div>
                            <div className='home-description-point-set'>
                                <div className='home-description-heading'>Why Sentence Architect ?</div>
                                <div className='home-description-point'>
                                    <div className='home-description-number-circle-outside'>
                                        <div className='home-description-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='home-description-number'>1</div>
                                        </div>
                                    </div>
                                    <div className='home-description-text'>
                                        This competition highlights and celebrates the vast richness and versatility of the English language.
                                    </div>
                                </div>
                                <div className='home-description-point'>
                                    <div className='home-description-number-circle-outside'>
                                        <div className='home-description-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='home-description-number'>2</div>
                                        </div>
                                    </div>
                                    <div className='home-description-text'>
                                        Participants are provided an opportunity to evaluate their proficiency in this universally recognized medium of communication.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Home