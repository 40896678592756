import React, { useEffect, useState } from 'react';
import './landingPage.css'
import { NavBar, SideBar, ScrollButton } from './components';
import { Awards, Footer, Home, Themes } from './containers';
import { useViewPortMobileCheck } from '../../hooks/ViewPortContext';

const LandingPage = () => {
    useEffect(() => {
        document.title = "Sentence Architect - English Competition..."
    }, []);

    const isMobile = useViewPortMobileCheck()

    const [onTop, setOnTop] = useState(true);

    useEffect(() => {
        const changeNavbarColor = () => {
            if (document.getElementById("LandingContents").scrollTop >= 100) {
                if (onTop)
                    setOnTop(false);
            }
            else {
                if (!onTop)
                    setOnTop(true);
            }

        };

        const onPageLoad = () => {
            document.getElementById("LandingContents").addEventListener('scroll', changeNavbarColor);
        };

        const cleanup = () => {
            const landingContents = document.getElementById("LandingContents");
            if (landingContents) {
                landingContents.removeEventListener('scroll', changeNavbarColor);
            }
        };

        if (document.readyState === "complete") {
            onPageLoad();
            return cleanup;
        } else {
            window.addEventListener("load", onPageLoad);
            return () => {
                window.removeEventListener("load", onPageLoad);
                cleanup();
            };
        }

    }, [onTop]);
    const navBarStyle = {
        opacity: onTop ? '0' : '1',
        visibility: onTop ? 'hidden' : 'visible',
        borderColor: onTop ? 'transparent' : 'black',
        boxShadow: onTop ? (isMobile ? '' : '') : '0px 5px 7px 0px rgba(0, 0, 0, 0.2)',
        backgroundColor: onTop ? isMobile ? '' : 'white' : 'white',
        pointerEvents: onTop ? 'auto' : 'auto',
        height: isMobile ? '10vh' : '5vw',
        padding: isMobile ? '0 1rem' : '',
        transition: 'visibility 0.2s ease-in-out, opacity 0.2s ease-in-out, boxShadow 0.3s ease-in-out'
    }

    return (
        <div className='landingPage'>

            <nav>
                <div className='landingPage-navbar-sticky'>
                    <div className='landingPage-navbar' style={navBarStyle}>
                        <NavBar />
                    </div>
                </div>
                {
                    isMobile ?
                        <SideBar />
                        :
                        <></>
                }
            </nav>

            <ScrollButton id={"LandingContents"} />
            
            <div className='landingPage-content-container' >
                <div className='landingPage-contents' id="LandingContents">
                    <main>
                        <header>
                            <Home />
                        </header>

                        <section>
                            <Themes />
                        </section>

                        <section>
                            <Awards />
                        </section>

                        <footer>
                            <Footer />
                        </footer>

                    </main>
                </div>
            </div>
        </div>
    )
}

export default LandingPage