import React from 'react';
import { useViewPortMobileCheck } from '../../../../hooks/ViewPortContext';
import DesktopThemes from './desktop/DesktopThemes';
import MobileThemes from './mobile/MobileThemes';

const Themes = () => {
    const isMobile = useViewPortMobileCheck()

    return isMobile
    ? (
        <MobileThemes />
    )
    : (
        <DesktopThemes />
    )
}

export default Themes