import React from 'react';
import './navbar.css'
import { useViewPortMobileCheck } from '../../../../hooks/ViewPortContext';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';

const NavBar = () => {
    const isMobile = useViewPortMobileCheck();

    return isMobile
        ? (
            <MobileNavBar/>
        )
        : (
            <DesktopNavBar/>
        )
}

export default NavBar